<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3 mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class=" col-12 md:col-8 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">User registration</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-4 comp-grid" >
                            <div class="">
                                <div class="flex align-items-center">
                                    <div>Already have an account?</div>
                                    <div class="ml-2">
                                        <router-link  to="/">
                                            <Button icon="pi pi-user" label="Login" /> 
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container-fluid">
                <div class="grid justify-content-center">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <form ref="observer"  tag="form" @submit.prevent="submitForm()">
                                    <div class="grid">
                                        <div class="col-12">
                                            Username *
                                            <div id="ctrl-username-holder"> 
                                                <check-duplicate v-model="formData.username" check-path="components_data/users_username_exist/" v-slot="checker">
                                                <InputText ref="ctrlusername" @blur="checker.check" :loading="checker.loading" v-model.trim="formData.username"  label="Username" type="text" placeholder="Enter Username"      
                                                class=" w-full" :class="getErrorClass('username')">
                                                </InputText>
                                                <small v-if="isFieldValid('username')" class="p-error">{{ getFieldError('username') }}</small> 
                                                <small v-if="!checker.loading && checker.exist" class="p-error"> Not available</small>
                                                <small v-if="checker.loading" class="text-500">Checking...</small>
                                                </check-duplicate>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            Pass *
                                            <div id="ctrl-pass-holder"> 
                                                <Password ref="ctrlpass" v-model="formData.pass" label="Pass" placeholder="Enter Pass"   
                                                class="w-full" inputClass="w-full" toggleMask :feedback="true" :class="getErrorClass('pass')" />
                                                <small v-if="isFieldValid('pass')" class="p-error">{{ getFieldError('pass') }}</small> 
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            Confirm Password *
                                            <div id="ctrl-pass-holder"> 
                                                <Password  class="w-full" :class="getErrorClass('pass')" inputClass="w-full" :feedback="false" toggleMask ref="ctrlconfirmpass" v-model="formData.confirm_password" label="Confirm Password" placeholder="Confirm Password"  />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            Email *
                                            <div id="ctrl-email-holder"> 
                                                <check-duplicate v-model="formData.email" check-path="components_data/users_email_exist/" v-slot="checker">
                                                <InputText ref="ctrlemail" @blur="checker.check" :loading="checker.loading" v-model.trim="formData.email"  label="Email" type="email" placeholder="Enter Email"      
                                                class=" w-full" :class="getErrorClass('email')">
                                                </InputText>
                                                <small v-if="isFieldValid('email')" class="p-error">{{ getFieldError('email') }}</small> 
                                                <small v-if="!checker.loading && checker.exist" class="p-error"> Not available</small>
                                                <small v-if="checker.loading" class="text-500">Checking...</small>
                                                </check-duplicate>
                                            </div>
                                        </div>
                                        <input name="ctrldatecreate"  ref="ctrldatecreate" v-model="formData.datecreate" type="hidden" />
                                        <input name="ctrlrole"  ref="ctrlrole" v-model="formData.role" type="hidden" />
                                        <div class="col-12">
                                            User Role Id 
                                            <div id="ctrl-user_role_id-holder"> 
                                                <api-data-source   api-path="components_data/role_id_option_list" >
                                                    <template v-slot="req">
                                                        <Dropdown class="w-full" :class="getErrorClass('user_role_id')"   :loading="req.loading"   optionLabel="label" optionValue="value" ref="ctrluser_role_id"  v-model="formData.user_role_id" :options="req.response" label="User Role Id"  placeholder="Select a value ..." >
                                                        </Dropdown> 
                                                        <small v-if="isFieldValid('user_role_id')" class="p-error">{{ getFieldError('user_role_id') }}</small> 
                                                    </template>
                                                </api-data-source>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button type="submit" label="Submit" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, email, sameAs, numeric, } from '@/services/validators';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useAuth } from '@/composables/auth';
	import { useAddPage } from '@/composables/addpage.js';
	const props = defineProps({
		pageName : {
			type : String,
			default : 'users',
		},
		routeName : {
			type : String,
			default : 'usersuserregister',
		},
		apiPath : {
			type : String,
			default : 'auth/register',
		},
		submitButtonLabel: {
			type: String,
			default: "Submit",
		},
		msgAfterSave: {
			type: String,
			default: "Record added successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
		pageData: { // use to set formData default values from another page
			type: Object,
			default: () => {} 
		},
	});
	const app = useApp();
	const auth = useAuth();
	const formDefaultValues = {
		username: "", 
		pass: "", 
		confirm_password: "", 
		email: "", 
		datecreate: utils.dateTimeNow(), 
		role: "null", 
		user_role_id: "", 
	}
	const formData = reactive({ ...formDefaultValues });
	// perform custom validation before submit form
	// set custom form fields
	// return false if validation fails
	function beforeSubmit(){
		return true;
	}
	// after form submited to api
	// reset form data.
	// redirect to another page
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterSave);
		Object.assign(formData, formDefaultValues); //reset form data
		let registrationData = response.data;
		if (registrationData.token && registrationData.user) {
			auth.saveLoginData(registrationData, false);
			location.href = "/"; //reload page and navigation to home page
		}
		else{
			app.flashMsg(registrationData.message);
			app.navigateTo(registrationData.nextpage);
		}
	}
	//form validation rules
	const rules = computed(() => {
		return {
			username: { required },
			pass: { required },
			confirm_password: {required, sameAs: sameAs(formData.pass, 'Pass') },
			email: { required, email },
			datecreate: { required },
			role: {  },
			user_role_id: { numeric }
		}
	});
	const v$ = useVuelidate(rules, formData); // form validation
	const page = useAddPage({ props, formData, v$, onFormSubmited, beforeSubmit });
	//page state
	const {
		submitted, // form submitted state - Boolean
		saving, // form api submiting state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page methods
	const {
		submitForm, //submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname)
		getFieldError, //  get validation error message - args(fieldname)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Add New Users";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped>
</style>
